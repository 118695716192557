import {ServiceConfiguration} from "../modules/ServiceConfiguration/core/ServiceConfiguration";

export class Customer {
    details?: CustomerAnagDetails;
    contacts?: Contact[] = [];
    subs?: Subscription[] = [];
    groups?: Group[] = [];
    serviceActivations?: ServiceActivation[];
}

export class Group {
    groupId: number;
    ownerId: number;
    owner?: CustomerAnagDetails
    groupType?: GroupType;
    description?: string = '';
    members: CustomerAnagDetails[];

    constructor(ownerId?: number) {
        this.ownerId = ownerId;
    }
}

export class Summary {
    companyId: number;
    delta: number;
    mr: number;
    cmr: number;
    businessName: string;

    constructor() {
        this.companyId = null;
        this.delta = null;
        this.mr = null;
        this.cmr = null;
        this.businessName = null;
    }
}

export class CustomerAnagDetails {
    customerId: number;
    tppCompanyId?: number;
    companyCode?: string;
    customerCode?: string;
    vatNumber?: string;
    businessName?: string;
    taxCode?: string;
    customerType?: CustomerType;
    customerStatus?: CustomerStatus;
    iban?: string;
    paymentDuePeriod?: number;
    paymentMethod?: string;
    paymentType?: string;
    isBillingAuto?: boolean;
    startingDate?: string;
    cessationDate?: string;
    lastAmlValidationDate?: string;
    lastAmlValidationStatus?: string;

    constructor(customerId?: number) {
        this.customerId = customerId;
        this.isBillingAuto = false;
        this.companyCode = '';
        this.customerCode = '';
        this.vatNumber = '';
        this.businessName = '';
        this.taxCode = '';
        this.iban = '';
        this.paymentMethod = '';
        this.paymentType = '';
        this.startingDate = '';
        this.cessationDate = '';
        this.lastAmlValidationDate = '';
        this.lastAmlValidationStatus = '';
    }
}

export class CustomerMonitoringAnagDetails {
    tppCompanyId?: number;
    ratios?: CompanyRatios[];
    banks?: BanksRatios[];

    constructor(tppCompanyId?: number) {
        this.tppCompanyId = tppCompanyId;
        this.ratios = [];
    }
}

export class CompanyRatios {
    registrationDate?: string;
    executionDate?: string;
    inputAisCount?: number;
    inputStandbyCount?: number;
    inputTotalCount?: number;
    matchedCount?: number;
    standbyCount?: number;
    errorCount?: number;
    mr1?: number;
    sb1?: number;
    fr1?: number;
    cr1?: number;
    cmr?: number;
    rejectCount?: number;
    cancelledCount?: number;
    titleMatchingRates?: number;

    constructor() {
        this.registrationDate = "";
        this.inputAisCount = null;
        this.inputStandbyCount = null;
        this.inputTotalCount = null;
        this.matchedCount = null;
        this.standbyCount = null;
        this.errorCount = null;
        this.mr1 = null;
        this.sb1 = null;
        this.fr1 = null;
        this.cr1 = null;
        this.cmr = null;
        this.rejectCount = null;
        this.cancelledCount = null;
    }
}

export class BaseBanksRatios {
    registrationDate?: string;
    bankBalance?: number;
    erpBalance?: number;
    delta?: number;

    constructor() {
        this.registrationDate = "";
        this.bankBalance = null;
        this.erpBalance = null;
        this.delta = null;
    }
}

export class BanksRatios extends BaseBanksRatios {
    data?: BankRatios[];

    constructor() {
        super()
        this.data = [];
    }
}

export class BankRatios {
    bankName?: string;
    iban?: string;
    bankAmount?: number;
    erpAmount?: number;

    constructor() {
       this.bankName = "";
       this.iban = "";
       this.bankAmount = null;
       this.erpAmount = null;
    }
}

export class CompanySummary {
    companyId: number;
    delta: number;
    mr: number;
    cmr: number;

    constructor() {
        this.companyId = null;
        this.delta = null;
        this.mr = null;
        this.cmr = null;
    }
}

export enum CustomerType {
    GROUP = 'GA',
    OFFICE = 'C',
    SINGLE = 'A',
    VENDOR = 'V'
}

export enum CustomerStatus {
    L00 = "L00",
    L01 = "L01",
    L02 = "L02",
    L03 = "L03",
    L04 = "L04",
    L05 = "L05",
    L0_ = "L0_",
    L0X = "L0X"
}

export enum ServiceStatus {
    L10 = "L10",
    L11 = "L11",
    L12 = "L12",
    L13 = "L13",
    L14 = "L14",
    L15 = "L15",
    L1X = "L1X",
    L1_ = "L1_"
}

export enum ContactType {
    CONTATTO = 1,
    FATTURAZIONE = 2,
    ADDRESS = 0,
    NOTIFY = 3
}

export enum GroupType {
    GRUPPO = 1,
    FATTURAZIONE = 2
}

export enum SubscriptionType {
    FLAT= 'FLAT',
    PAY_FOR_USE = 'PAYXUSE'
}

export class Contact {
    contactId: number;
    customerId: number;
    name?: string;
    surname?: string;
    phoneNumber?: string;
    description?: string;
    email?: string;
    address?: string;
    isEnable: boolean;
    isFavorite: boolean;
    contactType: ContactType;

    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    fax?: string;

    constructor() {
        this.contactId = null;
        this.contactType = ContactType.CONTATTO;
    }

}

export enum BILLING_TYPE {
    ANTICIPATO = 1,
    CONSUNTIVO = 2
}

export class Subscription {
    customer: CustomerAnagDetails;
    rate: Rate;
    service: Service;
    customerId: number;
    subscriptionId: number;
    subscriptionType: SubscriptionType;
    startDate: string;
    endDate: string;
    trialStartDate: string;
    trialEndDate: string;
    serviceFrequency: number; // frequenza esecuzione servizio
    serviceFrequencyCharge: number;
    extraCharge: number;
    amount: number;
    discount: number;
    serviceActivation: ServiceActivation;
    contractCode: string = '';
    contractName: string = '';
    contractDate: string;
    thresholds?: ThresholdsT[] = [];
    billingFrequency: number; // frequenza fatturazione
    rateFrequency: number; // frequenza rate

    billingType: BILLING_TYPE; // Tipologia fatturazione anticipto / consuntivo
    //defaultFrequencyPeriod: FREQUENCY_PERIOD // Orario, Giornaliero, Settimanale, Mensile



    constructor(customer: CustomerAnagDetails, service: Service = null) {
        this.customer = customer;
        this.contractName = customer ? `-${customer?.customerCode}` : '';
        this.service = service;
        this.billingType = BILLING_TYPE.CONSUNTIVO;
        this.subscriptionType = SubscriptionType.FLAT;
    }

}

export class ServiceActivation {
    serviceActivationId: number;
    serviceActivationStatus?: ServiceStatus;
    serviceActivationDate?: string;
    isBillingEnable?: boolean;
    service?: Service;
    customer?: CustomerAnagDetails;
    serviceErpPlatform: string;

    constructor() {
        this.service = new Service();
        this.customer = new CustomerAnagDetails();
        this.isBillingEnable = false;
    }

}

export class Rate {
    rateId: number;
    name?: string;
    description?: string;
    expirationDate?: string;
    thresholds?: ThresholdsT[] = [];
    customerType?: CustomerType;
    billingFrequency?: number;
    isDefault?: boolean;
    trialLength?: number;
    // defaultFrequency?: number;
    deltaFrequency?: number
    rateFrequency?: number;
    isPriceList?: boolean;
    serviceConfigId?: number;
    // defaultFrequencyPeriod?: FREQUENCY_PERIOD // Orario, Giornaliero, Settimanale, Mensile
}

export class RateT extends Rate {
    businessDrivers: BusinessDriverT[] = [];
    serviceConfig?: ServiceConfiguration;

    constructor() {
        super();
        this.serviceConfig = new ServiceConfiguration();
    }
}

export class BusinessDriverT {
    businessDriverId: number;
    name?: string;
    description?: string;
    isAuto?: boolean;
    thresholds: ThresholdsT[];
}

export class ThresholdsT {
    //businessDriverId: number;
    thresholdId: number;
    businessDriver?: BusinessDriverT;
    detectionPeriod?: number;
    flatCost?: number;
    unitCost?: number;
    value?: number;
}

export class Service {
    serviceId: number;
    name?: string;
    description?: string;
    batchSize?: number;
}

export class Fee {
    vendorId: number;
    customerId: number;
    serviceId: number;
    serviceName: string;
    year: number;
    value: number;
}

export enum PaymentType {
    TP01= 'TP01',
    TP02= 'TP02',
    TP03= 'TP03'
}

export enum PaymentMethod {
    MP01 = 'MP01',
    MP02 = 'MP02',
    MP03 = 'MP03',
    MP04 = 'MP04',
    MP05 = 'MP05',
    MP06 = 'MP06',
    MP07 = 'MP07',
    MP08 = 'MP08',
    MP09 = 'MP09',
    MP10 = 'MP10',
    MP11 = 'MP11',
    MP12 = 'MP12',
    MP13 = 'MP13',
    MP14 = 'MP14',
    MP15 = 'MP15',
    MP16 = 'MP16',
    MP17 = 'MP17',
    MP18 = 'MP18',
    MP19 = 'MP19',
    MP20 = 'MP20',
    MP21 = 'MP21',
    MP22 = 'MP22',
    MP23 = 'MP23'
}