import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {Batch} from "../core/CustomerConfiguration";
import {AxiosResponse} from "axios";

export function useBatches() {
    const [batches, setBatches] = React.useState<Batch[]>([]);
    const {isLoading, request} = useAxios();

    const get = (serviceId: number): Promise<Batch[]> => {
        return request({ method: "GET", url:`${process.env.REACT_APP_M1A009}/batch?serviceId=${serviceId}` })
            .then((res: AxiosResponse<Batch[]>) => {
                setBatches(res.data);
                return res.data;
            })
            .catch(_ => {
                setBatches([]);
                return [];
            });
    }

    return {batches, get, isLoading};
}