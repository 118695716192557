import React from "react";
import Typography from "@mui/material/Typography";
import { Batch, BatchParamsValue } from "../core/ServiceConfiguration";
import {Box, Checkbox, Grid, TextField} from "@mui/material";

interface ServiceBatchConfigurationProps {
    batches: Batch[];
    batchParamsValue: BatchParamsValue[];
    onChange: (p: BatchParamsValue[]) => void;
}

function ServiceBatchConfigurationComponent({
    batches,
    batchParamsValue,
    onChange
}: ServiceBatchConfigurationProps) {
    const getValue = (batchId: number, paramName: string) => {
        const v = batchParamsValue?.find(
            (p) => p.batchId === batchId && p.paramName === paramName
        );

        return v ? v.paramValue : null;
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        batchId: number,
        paramName: string
    ) => {
        const newValue =
            event.target.type === "checkbox"
            ? (event.target as HTMLInputElement).checked.toString()
            : event.target.value;

        const newValues = batchParamsValue.map((p) => {
            if (p.batchId === batchId && p.paramName === paramName) {
                p.paramValue = newValue;
            }
            return p;
        });
        onChange(newValues);
    };

    return (
        <>
            <Typography variant="h6">Configurazione Parametri</Typography>
            {batches.map((batch: Batch) =>
                batch.batchParamList.length > 0 ? (
                    <Box
                        key={batch.batchId}
                        display="flex"
                        flexDirection="column"
                        gap=".5rem"
                    >
                        <Typography fontWeight="bold">{batch.batchDescription}</Typography>
                        {batch.batchParamList.map((param) => {
                            const value = getValue(batch.batchId, param.paramName);

                            return (
                                <Grid container key={param.paramName} columns={2}>
                                    <Grid item xs={1}>
                                        <Typography>{param.paramDescription}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {param.valueType === 'boolean' ? (
                                            <Checkbox
                                                defaultValue={param.defaultValue}
                                                checked={value === "true"}
                                                onChange={(e) => handleChange(e, batch.batchId, param.paramName)}
                                            />
                                            ) : (
                                            <TextField
                                                type={param.valueType === 'string' ? 'text' : 'number'}
                                                value={value}
                                                onChange={(e) => handleChange(e, batch.batchId, param.paramName)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Box>
                ) : null
            )}
        </>
    );
}

export default ServiceBatchConfigurationComponent;
