import React from "react";
import {useAxios} from "../../../core/axiosHook";
import {ServiceConfiguration} from "../../ServiceConfiguration/core/ServiceConfiguration";
import {
    CustomerConfiguration,
    CustomerConfigurationAssignRequest
} from "../core/CustomerConfiguration";
import {AxiosResponse} from "axios";


export function useCustomerConfiguration() {
    const [customerConfiguration , setCustomerConfiguration] = React.useState<CustomerConfiguration>();
    const {isLoading, request} = useAxios();

    const get = (customerId: number, serviceId: number): Promise<CustomerConfiguration> => {
        setCustomerConfiguration(null);
        const url = `${process.env.REACT_APP_M1A009}/serviceConfig/customer/${customerId}`;

        return Promise.all([
            request({ method: "GET", url:`${url}?serviceId=${serviceId}` }),
            request({ method: "GET", url:`${url}/batchParams?serviceId=${serviceId}` })
        ]).then(([res, r]) => {
            const data: CustomerConfiguration = res.data?.length ? res.data[0] : new CustomerConfiguration();
            const config = {
                ...data,
                customerBatchParamList: [...r.data]
            }

            setCustomerConfiguration(config);
            return config;
        });
    }

    const save = (values: CustomerConfigurationAssignRequest) => {
        return request({ method: "POST",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig/customer/assign`, data: values }).then(res => {
            setCustomerConfiguration(res.data);
        });
    }

    return {customerConfiguration, isLoading, get, save};
}

export function useCustomerConfigurations() {
    const [customerConfigurations , setCustomerConfigurations] = React.useState<CustomerConfiguration[]>();
    const {isLoading, request} = useAxios();

    const get = (customerId: number) => {
        request({ method: "GET",
            url:`${process.env.REACT_APP_M1A009}/serviceConfig/customer/${customerId}`
        }).then((res: AxiosResponse<CustomerConfiguration[]>) => {
            setCustomerConfigurations(res.data);
        });

    }

    return {customerConfigurations, isLoading, get};
}

export function useServiceConfigurations() {
    const [serviceConfigurations, setServiceConfigurations] = React.useState<ServiceConfiguration[]>([]);

    const {isLoading, request} = useAxios();

    const get = (serviceId: number): Promise<ServiceConfiguration[]>  => {
        setServiceConfigurations([]);
        return request({ method: "GET", url:`${process.env.REACT_APP_M1A009}/serviceConfig?serviceId=${serviceId}` })
            .then(res => {
                setServiceConfigurations(res.data);
                return res.data;
            });
    }

    return {serviceConfigurations, get, isLoading};
}