import React from "react";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {useCustomer} from "../hook/useCustomer";
import {CustomerComponentProps} from "./CustomerComponentProps";
import {CustomerAnagDetails} from "../../../model/Customer";
import {AxiosResponse} from "axios";
import {
    Button,
    Card, CardActions,
    CardContent,
    CardHeader, Checkbox,
    FormControl, FormControlLabel, FormGroup, FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Trans, useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import {useFormik} from "formik";
import {CustomerStatuses, CustomerTypes, PaymentMethods, PaymentTypes} from "../core/DataSources";
import {useNavigate} from "react-router-dom";
import {CustomerValidation} from "../core/validation/CustomerValidation";
import {ROUTES} from "../../../routes/ROUTES";


function CustomerAnag(props: CustomerComponentProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [edit, setEdit] = React.useState(false);
    const [values, setValues] = React.useState(new CustomerAnagDetails());

    const mapDetails = (res: AxiosResponse<CustomerAnagDetails>) => {
        setValues(res.data);
        return {
            details: res.data
        }
    }

    React.useEffect(() => {
        setEdit(props.isNewCustomer);
    }, [props.isNewCustomer])

    const customerHook = useCustomer(`/customers/`,props.id, mapDetails);

    const header = <div className="section-header">
        <Typography variant="h6" component="div">
            <Trans>CUSTOMER_DETAILS.FIELDS.DETAILS.TITLE</Trans>
        </Typography>
        {!edit
            ? <Button
                aria-label="edit"
                onClick={_ => setEdit(!edit)}
            >
                <EditIcon ></EditIcon>
                <Typography>
                    <Trans>COMMON.EDIT</Trans>
                </Typography>
            </Button>
            : <></>
        }
    </div>;

    const formik = useFormik({
        initialValues: values,
        enableReinitialize: true,
        validationSchema: CustomerValidation(t),
        onSubmit: (values) => {
            customerHook.saveCustomer(values,
                Boolean(props.id),
                (customerResponse) => {
                    if (!props.id) {
                        navigate(ROUTES.EDIT_CUSTOMER + customerResponse.data.customerId)
                    }
                    return customerResponse.data;
                }
                );
            setEdit(false);
        },
    });

    const label = (value: string) => {
        return <Trans>{value}</Trans>;
    }

    return (
        <SuspenseComponent isLoading={customerHook.isLoading}>
            {values &&
                <Card>
                    <CardHeader title={header} />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}
                            style={{display:"flex", flexDirection: "column", gap: "1rem"}}>
                            <FormGroup className="form-group-horizontal">
                                <TextField
                                    disabled={!edit}
                                    variant="standard"
                                    id="customerCode"
                                    name="customerCode"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.CODE")}
                                    value={formik.values.customerCode ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.customerCode && Boolean(formik.errors.customerCode)}
                                    helperText={formik.touched.customerCode && formik.errors.customerCode}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled
                                    variant="standard"
                                    id="tppCompanyId"
                                    name="tppCompanyId"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.COMPANY_ID")}
                                    value={formik.values.tppCompanyId ?? ''}
                                    onBlur={formik.handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled={!edit}
                                    type="number"
                                    variant="standard"
                                    id="companyCode"
                                    name="companyCode"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.COMPANY_CODE")}
                                    value={formik.values.companyCode ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormControl variant="standard" sx={{minWidth: 120 }}>
                                    <InputLabel id="customerTypeLabel" error={Boolean(formik.errors.customerType)}>
                                        <Trans>CUSTOMER_DETAILS.FIELDS.DETAILS.TYPE</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="customerTypeLabel"
                                        id="customerType"
                                        value={formik.values.customerType ?? ''}
                                        name="customerType"
                                        disabled={!edit}
                                        onChange={formik.handleChange}
                                        label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.TYPE")}
                                    >
                                        {CustomerTypes.map(tp => <MenuItem key={tp} value={tp}><Trans>CUSTOMER_TYPES.{tp}</Trans></MenuItem> )}
                                    </Select>
                                    <FormHelperText error>{formik.errors.customerType}</FormHelperText>
                                </FormControl>
                                <FormControl variant="standard" sx={{minWidth: 120 }}>
                                    <InputLabel id="customerStatusLabel" error={Boolean(formik.errors.customerStatus)}>
                                        <Trans>CUSTOMER_DETAILS.FIELDS.DETAILS.STATUS</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="customerStatusLabel"
                                        id="customerStatus"
                                        name="customerStatus"
                                        value={formik.values.customerStatus ?? ''}
                                        disabled={!edit}
                                        onChange={formik.handleChange}
                                        label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.TYPE")}
                                    >
                                        {CustomerStatuses.map(tp => <MenuItem key={tp} value={tp}><Trans>CUSTOMER_STATUS.{tp}</Trans></MenuItem> )}
                                    </Select>
                                    <FormHelperText error>{formik.errors.customerStatus}</FormHelperText>
                                </FormControl>
                            </FormGroup>
                            <FormGroup className="form-group-horizontal">
                                <TextField
                                    disabled={!edit}
                                    variant="standard"
                                    id="vatNumber"
                                    name="vatNumber"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.VAT_NUMBER")}
                                    value={formik.values.vatNumber ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.vatNumber && Boolean(formik.errors.vatNumber)}
                                    helperText={formik.touched.vatNumber && formik.errors.vatNumber}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled={!edit}
                                    variant="standard"
                                    id="taxCode"
                                    name="taxCode"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.TAX_CODE")}
                                    value={formik.values.taxCode ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.taxCode && Boolean(formik.errors.taxCode)}
                                    helperText={formik.touched.taxCode && formik.errors.taxCode}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    fullWidth
                                    disabled={!edit}
                                    variant="standard"
                                    id="businessName"
                                    name="businessName"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.NAME")}
                                    value={formik.values.businessName ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                                    helperText={formik.touched.businessName && formik.errors.businessName}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormGroup>
                            <FormGroup className="form-group-horizontal">

                            </FormGroup>
                            <FormGroup className="form-group-horizontal">
                                <TextField
                                    disabled
                                    variant="standard"
                                    type="date"
                                    id="lastAmlValidationDate"
                                    name="lastAmlValidationDate"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.LAST_AML_DATE")}
                                    value={formik.values.lastAmlValidationDate ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled
                                    variant="standard"
                                    id="lastAmlValidationStatus"
                                    name="lastAmlValidationStatus"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.LAST_AML_STATUS")}
                                    value={formik.values.lastAmlValidationStatus ?? ''}
                                    onBlur={formik.handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled
                                    type="date"
                                    variant="standard"
                                    id="startingDate"
                                    name="startingDate"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.START_DATE")}
                                    value={formik.values.startingDate ?? ''}
                                    onBlur={formik.handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled
                                    type="date"
                                    variant="standard"
                                    id="cessationDate"
                                    name="cessationDate"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.CESSATION_DATE")}
                                    value={formik.values.cessationDate ?? ''}
                                    onBlur={formik.handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />

                            </FormGroup>

                            <FormGroup className="form-group-horizontal">
                                <TextField
                                    disabled={!edit}
                                    variant="standard"
                                    id="iban"
                                    name="iban"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.IBAN")}
                                    value={formik.values.iban ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.iban && Boolean(formik.errors.iban)}
                                    helperText={formik.touched.iban && formik.errors.iban}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormControl variant="standard" sx={{minWidth: 120 }}>
                                    <InputLabel id="paymentTypeLabel" error={Boolean(formik.errors.paymentType)}>
                                        <Trans>CUSTOMER_DETAILS.FIELDS.DETAILS.PAYMENT_TYPE</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="paymentTypeLabel"
                                        id="paymentType"
                                        value={formik.values.paymentType ?? ''}
                                        name="paymentType"
                                        disabled={!edit}
                                        onChange={formik.handleChange}
                                        label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.PAYMENT_TYPE")}
                                    >
                                        {PaymentTypes.map(pt => <MenuItem key={pt} value={pt}><Trans>PAYMENT_TYPES.{pt}</Trans></MenuItem> )}
                                    </Select>
                                    <FormHelperText error>{formik.errors.paymentType}</FormHelperText>
                                </FormControl>
                                <FormControl variant="standard" sx={{minWidth: 120 }}>
                                    <InputLabel id="paymentMethodLabel" error={Boolean(formik.errors.paymentMethod)}>
                                        <Trans>CUSTOMER_DETAILS.FIELDS.DETAILS.PAYMENT_METHOD</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="paymentMethodLabel"
                                        id="paymentMethod"
                                        value={formik.values.paymentMethod ?? ''}
                                        name="paymentMethod"
                                        disabled={!edit}
                                        onChange={formik.handleChange}
                                        label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.PAYMENT_METHOD")}
                                    >
                                        {PaymentMethods.map(pm => <MenuItem key={pm} value={pm}><Trans>PAYMENT_METHODS.{pm}</Trans></MenuItem> )}
                                    </Select>
                                    <FormHelperText error>{formik.errors.paymentMethod}</FormHelperText>
                                </FormControl>

                                <TextField
                                    disabled={!edit}
                                    type="number"
                                    variant="standard"
                                    id="paymentDuePeriod"
                                    name="paymentDuePeriod"
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.PAYMENT_DUE_DATE")}
                                    value={formik.values.paymentDuePeriod ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.paymentDuePeriod && Boolean(formik.errors.paymentDuePeriod)}
                                    helperText={formik.touched.paymentDuePeriod && formik.errors.paymentDuePeriod}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormControlLabel
                                    label={label("CUSTOMER_DETAILS.FIELDS.DETAILS.IS_BILLING_AUTO")}
                                    control={
                                        <Checkbox
                                            disabled={!edit}
                                            id="isBillingAuto"
                                            name="isBillingAuto"
                                            value={formik.values.isBillingAuto ?? ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    }
                                />


                            </FormGroup>

                            {edit &&
                                <CardActions style={{justifyContent: 'flex-end'}}>
                                    {!props.isNewCustomer && <Button color="error" onClick={_ => setEdit(!edit)} disabled={!edit}><Trans>COMMON.CANCEL</Trans></Button>}
                                    <Button type="submit" disabled={!edit}><Trans>COMMON.SAVE</Trans></Button>
                                </CardActions>
                            }
                        </form>
                    </CardContent>
                </Card>
            }
        </SuspenseComponent>
    )
}

export default CustomerAnag;