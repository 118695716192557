import React, { useCallback, useState } from 'react';
import {
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, FormControlLabel, Tooltip
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {Trans} from "react-i18next";
import '../../../index.scss';
import {useCustomerConfiguration, useServiceConfigurations} from "../hook/useCustomerConfiguration";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {ServiceConfiguration} from "../../ServiceConfiguration/core/ServiceConfiguration";
import Cron from "../../../core/pmt-cron-editor/Cron";
import ServiceBatchConfigurationComponent
    from "../../ServiceConfiguration/components/ServiceBatchConfigurationComponent";
import {useBatches} from "../hook/useBatches";
import {Batch, BatchParamsValue, CustomerConfiguration, CustomerConfigurationAssignRequest} from "../core/CustomerConfiguration";
import {Service} from "../../../model/Customer";
import {SHORTCUTS} from "../../../core/pmt-cron-editor/core/constants";
import SuspenseComponent from "../../Layout/SuspenseComponent";



function CustomerConfigurationModal({
    customerId,
    data,
    onClose,
    open,
}: ModalComponentProps<Service>) {
    const [config, setConfig] = React.useState(new CustomerConfiguration());
    const [loading, setLoading] = useState(false);

    const customerConfigurationHook = useCustomerConfiguration();
    const serviceConfigurationsHook = useServiceConfigurations();
    const batchHook = useBatches();

    const updateConfig = useCallback((serviceConfig: ServiceConfiguration, customerConfig: CustomerConfiguration, batches: Batch[]) => {
        let conf = new CustomerConfiguration();

        if (serviceConfig) {
            const cron = SHORTCUTS.find(s => s.name === `@${serviceConfig.frequency.toUpperCase()}`);

            conf = {
                customerBatchParamList: serviceConfig.batchParamsValues,
                customerId: customerId,
                serviceConfig: serviceConfig,
                isEnable: true,
                cronExpression: cron ? cron.value : null
            };
        }

        if (customerConfig) {
            const p = customerConfig?.customerBatchParamList;
            conf = {
                ...conf,
                cronExpression: customerConfig?.cronExpression || conf.cronExpression,
                customerBatchParamList: p?.length ? [...conf.customerBatchParamList, ...p] : conf.customerBatchParamList,
                isEnable: customerConfig?.isEnable
            }
        }

        const customerBatchParamList: BatchParamsValue[] = [];
        batches.forEach(batch => {
            batch.batchParamList.forEach(param => {
                const val = conf.customerBatchParamList.find(customerBatchParam => customerBatchParam.batchId === batch.batchId && customerBatchParam.paramName === param.paramName);
                customerBatchParamList.push(new BatchParamsValue(batch.batchId, param.paramName, val ? val.paramValue : param.defaultValue));
            })
        });
        conf.customerBatchParamList = customerBatchParamList;

        setConfig(conf);
    }, [customerId]);

    React.useEffect(() => {
        // Load all data from backend
        if (customerId && data?.serviceId) {
            setLoading(true);

            Promise.all([
                customerConfigurationHook.get(customerId, data.serviceId),
                serviceConfigurationsHook.get(data.serviceId),
                batchHook.get(data.serviceId)
            ]).then(([customerConfig, serviceConfigs, batches]) => {
                // Find the current user configuration, if present, among all the available configurations
                let userServiceConfig: ServiceConfiguration = null;
                if (customerConfig && serviceConfigs) {
                    userServiceConfig = serviceConfigs.find(s => s.serviceConfigId === customerConfig.serviceConfig?.serviceConfigId);
                }

                updateConfig(userServiceConfig, customerConfig, batches);

                setLoading(false);
            })
        }
    }, [customerId, data?.serviceId]);

    const submit = () => {
        const c: CustomerConfigurationAssignRequest = {
            customerId: config.customerId,
            serviceConfigId: config.serviceConfig?.serviceConfigId,
            cronExpression: config.cronExpression,
            customerBatchParamList: config.customerBatchParamList,
            isEnable: config.isEnable
        }
        customerConfigurationHook.save(c).then(_ => handleClose());
    }

    const handleClose = () => {
        setConfig(new CustomerConfiguration());
        onClose();
    }

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={() => {
                return;
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle className="section-header">
                <Trans>CUSTOMER_DETAILS.FIELDS.CUSTOMER_CONFIGURATION.TITLE</Trans>
                <div>
                    <Button color="error" onClick={handleClose}><Trans>COMMON.CANCEL</Trans></Button>
                    <Button type="submit" onClick={submit}><Trans>COMMON.SAVE</Trans></Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <SuspenseComponent isLoading={loading}>
                    <div style={{padding: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5rem'}}>
                            <Autocomplete
                                clearOnEscape
                                disableClearable
                                fullWidth
                                id="search-customer"
                                options={serviceConfigurationsHook.serviceConfigurations}
                                value={config.serviceConfig}
                                onChange={(_, value: ServiceConfiguration) => {
                                    updateConfig(value, config, batchHook.batches);
                                }}
                                getOptionLabel={(option: ServiceConfiguration) => option?.serviceConfigName ?? ''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Configurazione"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                            <FormControlLabel
                                label='Stato:'
                                labelPlacement="start"
                                control={
                                    <Tooltip title={config?.isEnable ? 'Disabilita' : 'Abilita'}>
                                        <Checkbox
                                            checked={config?.isEnable}
                                            name="isEnable"
                                            id="isEnable"
                                            onChange={(e) => setConfig(old => ({ ...old, isEnable: e.target.checked }))}
                                        />
                                    </Tooltip>
                                }
                            />
                        </div>
                        {config.serviceConfig
                            ? <>
                                <Cron cron={config.cronExpression} onChange={(val) => setConfig(old => ({ ...old, cronExpression: val }))} />
                                <ServiceBatchConfigurationComponent
                                    batches={batchHook.batches}
                                    batchParamsValue={config.customerBatchParamList}
                                    onChange={(val) => setConfig(old => ({ ...old, customerBatchParamList: val }))}
                                />
                            </>
                            : <></>
                        }
                    </div>
                </SuspenseComponent>
            </DialogContent>
        </Dialog>
    );
}

export default CustomerConfigurationModal;